<template>
  <v-dialog
      max-width="800"
      scrollable
      v-model="dialog"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Quick view appointment</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <b>Appointment for: </b>{{appointment.admin_user_name}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Appointment by: </b>{{appointment.user_name || appointment.taken_for_name }}
            </v-col>

            <v-col cols="12" md="4">
              <b>Date & Time: </b>{{appointment.formatted_date}} {{appointment.formatted_month}}  {{appointment.formatted_time}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Email: </b>{{appointment.user_email}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Phone: </b>{{appointment.user_phone}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Status: </b> <span class="badge text-lg" v-bind:class="{
                        'badge-warning': appointment.status == 'waiting', 'badge-danger': appointment.status == 'cancelled',
                        'badge-info': appointment.status == 'scheduled', 'badge-success': appointment.status == 'attended', 'badge-secondary': appointment.status == 'missed'
                      }">
                        {{ appointment.status_text }}
                      </span>
            </v-col>

            <v-col cols="12" md="4">
              <b>Subject: </b>{{appointment.title}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Message: </b> <span v-html="appointment.description"></span>
            </v-col>

            <v-col cols="12" md="4">
              <b>Services: </b>
              <ul v-if="appointment && appointment.current_visas && appointment.current_visas.length > 0">
                <li class="ml-5" v-for="(item, index) in appointment.current_visas" :key="index">
                  {{item}}
                </li>
              </ul>
            </v-col>

          </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="closeDialog"
            color="mr-2" text dark
            medium
        >
          Cancel
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QuickView",
  data() {
    return {
      dialog: false,
      appointment: {},
    }
  },
  methods: {
    openDialog(item) {
      this.dialog = true;
      this.appointment = item;
    },
    closeDialog() {
      this.appointment = {};
      this.dialog = false;
    },
  }
}
</script>
