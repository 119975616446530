<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span> Update appointment</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-select
                label="Status"
                outlined
                dense
                v-model="appointment.status"
                :items="appointmentStatuses"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col cols="12" md="12">
              Remarks <span class="text-danger"> *</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="appointment.remarks"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.appointment.remarks.$error">This Field is required</span>

            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox
                  label="Notify email"
                  outlined
                  dense
                  v-model="appointment.is_notify"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="saveAppointmentStatus()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppointmentService from "@/services/appointment/AppointmentService";
import {required} from "vuelidate/lib/validators";

const appointment_service = new AppointmentService();

export default {
  name: "UpdateAppointmentStatus",
  data() {
    return {
        errors: [],
        dialog: false,
        loading: false,
        appointment: {
            status: '',
            remarks: ''
        },
        editorConfig: {
          versionCheck: false,
          toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
        },
        appointmentStatuses:[
            {
                name:'Scheduled',
                value: 'scheduled'
            },
            {
                name:'Waiting',
                value: 'waiting'
            },
            {
              name:'Attended',
              value: 'attended'
            },
            {
                name:'Cancelled',
                value: 'cancelled'
            },
        ]
    }
  },
  validations: {
    appointment: {
      remarks: {required},
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    openDialog() {
      this.dialog = true;
    },
    updateStatus(appointment) {
      this.resetForm();
      this.openDialog();
      this.appointment = appointment;
    },
    saveAppointmentStatus() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        appointment_service
            .updateStatus(this.appointment.id, this.appointment)
            .then((response) => {
              this.$snotify.success('Status changed successfully.');
              this.closeDialog();
              this.$emit('refresh');
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$snotify.error('Oops looks like something went wrong.');
            });
      }
    },
    resetForm() {
        this.errors=[];
        this.appointment = {
            status: '',
            remarks: ''
        };
    },
  }
}
</script>
