<template>
    <v-card flat min-height="500px">
      <v-card-title>
        User Access
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select chips multiple outlined dense label="User Role" v-model="selected_roles" :items="roles"
                      item-text="name" deletable-chips
                      item-value="name"></v-select>
          </v-col>
          <v-col cols="12">
            <v-select outlined dense label="User Type" v-model="user_type" :items="roles" item-text="name"
                      item-value="name"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer>
        </v-spacer>
        <v-btn class="btn btn-primary text-white" depressed @click="saveUserAccess">Save</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import RoleService from "@/services/user/role/RoleService";
import AdminUserService from "@/services/admin-user/AdminUserService";

const adminUserService = new AdminUserService();
const roleService = new RoleService();

export default {
  name: "UserAccess",
  props: ["admin_user"],
  data() {
    return {
      selected_roles: [],
      user_type: null,
      roles: []
    }
  },
  mounted() {
    this.getAllRoles();
    this.getUserRole();
    if (this.admin_user.user_type)
      this.user_type = this.admin_user.user_type;
  },
  methods: {
    getAllRoles() {
      roleService.all().then(response => {
        this.roles = response.data.data
      })
    },
    saveUserAccess() {
      this.updateAdminUser();
    },
    getUserRole() {
      adminUserService.userRoles(this.admin_user.id).then(response => {
        this.selected_roles = response.data
      }).catch(error => {
        this.roles = []
      })
    },
    updateAdminUser() {
      let data = {}
      data.user_type = this.user_type;
      adminUserService.updateUserType(this.admin_user.id, data).then(response => {
        this.refresh();
        this.assignRole()
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later")
      })
    },
    assignRole() {
      adminUserService.assignRole(this.admin_user.id, {roles: this.selected_roles}).then(response => {
        this.$snotify.success("Successfully assigned role to user");
        this.getUserRole();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later")
      })
    },
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
