import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AppointmentService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/appointment';
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    all(data={},index = null) {
        let url = `${this.#api}/get/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getAllCount(data={}){
        let url = `${this.#api}/get/all/counts`
        let param = {
            params: data
        }
        return apiService.query(url, param)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }

    delete(adminUserId) {
        let url = `${this.#api}/${adminUserId}`
        return apiService.delete(url)
    }
    updateStatus(id,data) {
        let url = `${this.#api}/${id}/update-status`;
        return apiService.post(url,data)
    }
}
