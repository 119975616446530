<template>
  <v-app>
    <v-list two-line>
      <v-list-item class="d-flex justify-space-between">
        <v-list-item-title>
          <h4> User Profile </h4>
        </v-list-item-title>
        <div class="d-flex">
          <v-btn
              class="btn mr-3 text-white btn-primary"
              small
              @click="editUser"
          >
            <i class="fa fa-edit"></i>
            Change Details
          </v-btn>
        </div>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.full_name ? admin_user.full_name : 'N/A'}}</v-list-item-title>
          <v-list-item-subtitle>Full Name</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.email ? admin_user.email : 'N/A'}}</v-list-item-title>
          <v-list-item-subtitle>Email</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-git
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.branch_name ? admin_user.branch_name : 'N/A'}}</v-list-item-title>
          <v-list-item-subtitle>Branch</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.phone ? admin_user.phone : ''}} {{admin_user.mobile ? admin_user.mobile : ''}}</v-list-item-title>
          <v-list-item-subtitle>Phone / Mobile</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-bank
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.bank_account_no || "N/A" }}</v-list-item-title>
          <v-list-item-subtitle>Bank account no</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-bank-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.bank_account_name || "N/A" }}</v-list-item-title>
          <v-list-item-subtitle>Bank account name</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-bank-check
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{admin_user.bank_branch || "N/A" }}</v-list-item-title>
          <v-list-item-subtitle>Bank branch</v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-bank-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-img
              v-if="admin_user.bank_qr_code_path"
              :src="admin_user.bank_qr_code_path.real"
              max-width="350"
              max-height="350"
          ></v-img>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-heart
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Status ?
          </v-list-item-title>
          <v-list-item-subtitle>
                        <span
                            class="badge"
                            :class="admin_user.is_active ? 'badge-success' : 'badge-danger' "
                        >
                            {{admin_user.is_active ? 'Active' : 'Inactive'}}
                        </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- Admin User models -->
      <v-dialog
          v-model="dialog"
          max-width="800"
          scrollable
          persistent
      >
        <v-card>
          <v-toolbar dark>
            <v-card-title class="text-h5">
              <span>Update User Details</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.first_name"
                      outlined
                      dense
                      :error="$v.userData.first_name.$error"
                  >
                    <template v-slot:label>
                      First Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.first_name.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.first_name" >* {{ errors.first_name[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.middle_name"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Middle Name
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.last_name"
                      outlined
                      dense
                      :error="$v.userData.last_name.$error"
                  >
                    <template v-slot:label>
                      Last Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.last_name.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.last_name" >* {{ errors.last_name[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                      :items="ourBranches"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      label="Branch"
                      v-model="userData.branch_id"
                  ></v-select>
                  <span class="text-danger" v-if="$v.userData.branch_id.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.branch_id" >* {{ errors.branch_id[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.mobile"
                      outlined
                      :error="$v.userData.mobile.$error"
                      dense
                  >
                    <template v-slot:label>
                      Mobile
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.mobile.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.mobile" >* {{ errors.mobile[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.phone"
                      outlined
                      :error="$v.userData.phone.$error"
                      dense
                  >
                    <template v-slot:label>
                      Phone
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.phone.$error">This field is required</span>
                  <span class="text-danger" v-if="errors.phone" >* {{ errors.phone[0] }}</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.address"
                      :error="$v.userData.address.$error"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Address
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.address.$error">This field is required</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.bank_account_no"
                      :error="$v.userData.bank_account_no.$error"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Bank account number <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.bank_account_no.$error">This Field is required</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.bank_account_name"
                      :error="$v.userData.bank_account_name.$error"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Bank account name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.bank_account_name.$error">This Field is required</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="userData.bank_branch"
                      :error="$v.userData.bank_branch.$error"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Bank branch <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.userData.bank_branch.$error">This Field is required</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                      v-model="userData.qr_code"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      accept="image/png"
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Bank QR code
                    </template>
                  </v-file-input>
                  <v-img
                      v-if="userData.bank_qr_code_path"
                      :src="userData.bank_qr_code_path.real"
                      max-width="200"
                      max-height="200"
                  ></v-img>
                </v-col>

                <v-col cols="12" md="6">
                  Status
                  <v-switch
                      v-model="userData.is_active"
                      :error="$v.userData.is_active.$error"
                      :label="userData.is_active ? 'Active': 'Inactive'"
                  ></v-switch>
                  <span class="text-danger" v-if="$v.userData.is_active.$error">This Field is required</span>
                  <span class="text-danger" v-if="errors.is_active">{{ errors.is_active[0] }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                text
                @click="closeDialog">
              Cancel
            </v-btn>

            <v-btn
                class="btn btn-primary"
                @click="update()"
                :loading="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-app>
</template>

<script>

import {required} from "vuelidate/lib/validators";
import AdminUserService from "@/services/admin-user/AdminUserService";
import BranchService from "@/services/branch/BranchService";

const adminUser = new AdminUserService();
const branchService = new BranchService();

export default {
  name: "UserDetails",
  props: ["admin_user"],
  validations: {
    userData:{
      first_name: {required},
      last_name: {required},
      branch_id: {required},
      phone: {required},
      mobile: {required},
      address: {required},
      bank_account_no: {required},
      bank_account_name: {required},
      bank_branch: {required},
      is_active: {required},
    }
  },
  data(){
    return{
      loading: false,
      dialog: false,
      countries: [],
      menuStartDate: false,
      menuDate: false,
      errors: [],
      userData:{
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        mobile: '',
        work_phone: '',
        phone: '',
        bank_account_no: '',
        bank_account_name: '',
        bank_branch: '',
        qr_code: null,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  methods:{
    showDialog(){
      this.dialog = true;
    },
    editUser(){
      this.userData = Object.assign({}, this.userData, this.admin_user);
      this.getAllBranches();
      this.showDialog();
    },
    closeDialog(){
      this.dialog = false;
      this.resetForm();
    },
    resetForm(){
      this.$v.$reset();
      this.errors = [];
      this.userData = {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        date_of_birth: '',
        mobile: '',
        work_phone: '',
        phone: '',
        bank_account_no: '',
        bank_account_name: '',
        bank_branch: '',
        qr_code: null,
      }
    },

    convertToFormData() {
      let formData = new FormData();
      for (let key in this.userData) {
        if (key === "qr_code" && this.userData[key] != null && this.userData[key] != undefined) {
          formData.append('qr_code', this.userData[key]);
        }else {
          if(this.userData[key]){
            formData.append(key,this.userData[key]);
          }
        }
      }
      return formData;
    },
    update(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        let formData = this.convertToFormData();
        adminUser
            .update(this.userData.id, formData)
            .then((response) => {
              this.$snotify.success("Update Successfully");
              this.$emit('refresh');
              this.closeDialog();
            })
            .catch((err) => {});
      }

    }
  }
}
</script>

<style scoped>

</style>
