import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AdminUserWorkingHourService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/admin-user';
    }
    index(adminUserId) {
        let url = `${this.#api}/${adminUserId}/working-hour`;
        return apiService.get(url);
    }

    create(data, adminUserId) {
        let url = `${this.#api}/${adminUserId}/working-hour`;
        return apiService.post(url, data)
    }

    delete(adminUserId) {
        let url = `${this.#api}/${adminUserId}`
        return apiService.delete(url)
    }

    getAvailableWorkingHours(adminUserId, data={}){
        let url = `${this.#api}/${adminUserId}/available/appointment/times`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
}
