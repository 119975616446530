<template>
  <v-app>

    <div class="main" style="background-color: #f3f6f9">
      <div>
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex mb-9">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">

                <v-avatar
                    class="profile"
                    color="grey"
                    size="100"
                    tile
                >
                  <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
                </v-avatar>
                <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                  <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                       href="#">{{ admin_user.first_name }}
                      {{ admin_user.last_name }}</a>
                    <a href="#">
                      <i class="flaticon2-correct text-success font-size-h5"></i>
                    </a>
                  </div>
                </div>
                <!--end::Title-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{ admin_user.email }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                         href="#">
                        <i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>{{ admin_user.mobile }}</a>
                      <a class="text-dark-50 text-hover-primary font-weight-bold"
                         href="#">
                        <i class="flaticon2-placeholder mr-2 font-size-lg"></i>{{ admin_user.address }}</a>
                    </div>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
            <div class="separator separator-solid"></div>

          </div>
        </div>
      </div>
      <v-row>
        <v-col cols="4" lg="3" md="3" sm="3">
          <v-tabs
              v-model="tab"
              vertical
          >
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Summary
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-eye
              </v-icon>
              Details
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-lock
              </v-icon>
              Security
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-calendar
              </v-icon>
              Appointments
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-calendar
              </v-icon>
              User Access
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="8" lg="9" md="9" sm="9">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-summary :upcoming_appointment="upcoming_appointment"></account-summary>
            </v-tab-item>
            <v-tab-item>
              <user-details :admin_user="admin_user"
                            @refresh="_detail"
              ></user-details>
            </v-tab-item>
            <v-tab-item>
              <security :admin_user="admin_user"
                        @refresh="_detail"></security>
            </v-tab-item>
            <v-tab-item>
              <follow-up-appointment
                  :admin_user_id="admin_user_id"
                  @work-hour-saved="_detail()"
              ></follow-up-appointment>
            </v-tab-item>
            <v-tab-item>
              <user-access v-if="admin_user_id" :admin_user="admin_user" @refresh="_detail"></user-access>
            </v-tab-item>
          </v-tabs-items>

        </v-col>
      </v-row>
    </div>
  </v-app>

</template>

<script>
import UserDetails from "./components/UserDetails";
import FollowUpAppointment from "./components/FollowUpAppointment";
import AccountSummary from "./components/AccountSummary";
import AdminUserService from "@/services/admin-user/AdminUserService";
import UserAccess from "@/view/pages/view/admin-user/profile/components/UserAccess";
import Security from "@/view/pages/view/admin-user/profile/components/Security";

const admin_user = new AdminUserService();
export default {
  name: "Index",
  components: {
    Security,
    UserDetails,
    FollowUpAppointment,
    AccountSummary,
    UserAccess
  },
  data() {
    return {
      tab: 0,
      admin_user_id: null,
      admin_user: {},
      upcoming_appointment: '',
    }
  },
  methods: {
    _detail() {
      if (this.admin_user_id != null || this.admin_user_id != undefined) {
        admin_user
            .show(this.admin_user_id)
            .then(response => {
              this.admin_user = response.data.user;
            })
            .catch(err => {

            })
            .finally(() => {

            });
      }
    },
    getUpcomingAppointment() {
      admin_user
          .getUpcomingAppointment(this.admin_user_id)
          .then((response) => {
            this.upcoming_appointment = response.data.data;
          })
    },

  },
  mounted() {
    this.admin_user_id = this.$route.params.admin_user_id;
    this._detail();
    this.getUpcomingAppointment();
  }
}
</script>
