<template>
  <v-app>
    <v-card>
      <v-card-title>
        Security
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-between">
          <label>MFA Enabled</label>
          <v-switch @change="update" v-model="admin_user.is_mfa_enabled" :disabled="!admin_user.is_mfa_enabled"></v-switch>
        </div>
        <div class="d-flex justify-content-between">
          <label>Email Authentication Enabled</label>
          <v-switch @change="update" v-model="admin_user.is_email_authentication_enabled" :disabled="!admin_user.is_email_authentication_enabled"></v-switch>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import AdminUserService from "@/services/admin-user/AdminUserService";

const adminUser = new AdminUserService();
export default {
  name: "Security",
  props: ["admin_user"],
  data() {
    return {
      userData: {}
    }
  },
  methods: {
    update() {
      adminUser
          .update(this.admin_user.id, this.admin_user)
          .then((response) => {
            this.$snotify.success('Update successfully');
            this.$emit('refresh');
          })
    },
  }
}
</script>

<style scoped>

</style>