<template>
    <v-app>
       <v-row>
<!--         <v-col cols="12">-->
<!--             <v-card raised>-->
<!--                 <v-card-title>-->
<!--                     <v-breadcrumbs :items="items" >-->
<!--                         <template v-slot:divider>-->
<!--                             <v-icon>mdi-forward</v-icon>-->
<!--                         </template>-->
<!--                         <template v-slot:item="{ item }">-->
<!--                             <v-breadcrumbs-item-->
<!--                                     :href="item.href"-->
<!--                                     :disabled="item.disabled"-->
<!--                             >-->

<!--                                 <v-chip small :color="item.disabled ? 'primary' : 'green'" dark>-->
<!--                                     {{ item.text.toUpperCase() }}-->
<!--                                 </v-chip>-->
<!--                             </v-breadcrumbs-item>-->
<!--                         </template>-->
<!--                     </v-breadcrumbs>-->
<!--                     <v-spacer>-->

<!--                     </v-spacer>-->

<!--                 </v-card-title>-->
<!--                 <v-card-text>-->

<!--                 </v-card-text>-->
<!--             </v-card>-->
<!--         </v-col>-->
         <v-col cols="12">
             <v-card class="mt-3">
               <v-card-title>
                 Next Follow Up
               </v-card-title>
               <v-card-text>
                 <v-alert
                     border="left"
                     color="yellow accent-4"
                     colored-border
                     dense
                     elevation="2"
                     v-if="upcoming_appointment"
                 >
                   <v-row>
                     <v-col cols="2">
                       <div class="d-flex flex-column flex align-items-center justify-content-center">
                         <div class="text-h2">
                           {{upcoming_appointment.formatted_date}}
                         </div>
                         {{upcoming_appointment.formatted_month}}
                       </div>

                     </v-col>
                     <v-col cols="10">
                       <div class="d-flex justify-content-around">
                         <div>
                           <v-icon color="yellow">
                             fas fa-circle
                           </v-icon>
                           <span class="subtitle-2">
                                        Upcoming
                                        </span>
                         </div>
                         <div>
                           <v-icon color="primary">
                             fas fa-clock
                           </v-icon>
                           <span class="subtitle-2">
                                        {{upcoming_appointment.formatted_time}}
                                        </span>
                         </div>
                         <div>
                           <v-icon color="primary">
                             fas fa-user
                           </v-icon>
                           <span class="subtitle-2">
                                        {{upcoming_appointment.admin_user_name}}
                                        </span>
                         </div>
                       </div>
                       <p class="p-2">
                         {{upcoming_appointment.note}}
                       </p>
                     </v-col>
                   </v-row>
                 </v-alert>
                 <div v-else>
                   There are no upcoming appointments.
                 </div>
               </v-card-text>
             </v-card>
           </v-col>
       </v-row>
    </v-app>

</template>

<script>
    export default {
        name: "AccountSummary",
        props: ["upcoming_appointment"],
        data: () => ({
            events: [],
            items: [
                {
                    text: 'INQUIRING',
                    disabled: true,
                    href: 'breadcrumbs_dashboard',
                },
                {
                    text: 'CLASS ENROLLMENT',
                    disabled: true,
                    href: 'breadcrumbs_link_1',
                },
                {
                    text: 'ABROAD ENROLLMENT',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'VISA DECISION',
                    disabled: false,
                    href: 'breadcrumbs_link_2',
                },
            ],
            managers: [
                {
                    name: 'Suraj Shrestha',
                    role: 'Australian Counselor,Admin',
                    assigned_by: "Suraj Shrestha",

                },
                {
                    name: 'Saurav Shrestha',
                    role: 'Australian Counselor,Admin',
                    assigned_by: "Saurav Shrestha",

                },
                {
                    name: 'Arun Aryal',
                    role: 'Australian Counselor,Admin',
                    assigned_by: "Arun Aryal",

                },

            ],
            details: [
                {
                    name:'College / University',
                    value:'CQ University'
                },
                {
                    name:'Course',
                    value:'BBA'
                },

                {
                    name:'Country',
                    value:'Australia'
                },

                {
                    name:'Level',
                    value:'Bachelor'
                },

                {
                    name:'Intake Period',
                    value:'January 2023'
                },
            ],
            input: null,
            nonce: 0,
        }),

        computed: {
            timeline () {
                return this.events.slice().reverse()
            },
        },

        methods: {
            comment () {
                const time = (new Date()).toTimeString()
                this.events.push({
                    id: this.nonce++,
                    text: this.input,
                    time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
                        return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
                    }),
                })

                this.input = null
            },
        },
    }
</script>

<style scoped>

</style>