import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class RoleService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin`;

    }

    paginate() {
        let url = `${this.#api}/auth/role`;
        return apiService.get(url);
    }

    all(except=false) {
        let url = `${this.#api}/auth/role`;
        if (except==true)
             url = `${this.#api}/auth/role?except=true`;
        return apiService.get(url);
    }


    update(id, data) {
        let url = `${this.#api}/auth/role/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}/auth/role`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/auth/role/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/auth/role/${id}`
        return apiService.delete(url);
    }
    setDefault(id){
        let url = `${this.#api}/role/${id}/default-permission`
        return apiService.get(url);
    }

    assignPermission(id, data) {
        let url = `${this.#api}/role/${id}/assign-permission`
        return apiService.post(url, data);
    }
    removePermission(id, data) {
        let url = `${this.#api}/role/${id}/remove-permission`
        return apiService.post(url, data);

    }
    rolePermissions(id) {
        let url = `${this.#api}/role/${id}/permissions`
        return apiService.get(url);
    }

}
