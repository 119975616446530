import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AdminUserService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/admin-user';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url,data)
    }

    delete(adminUserId) {
        let url = `${this.#api}/${adminUserId}`
        return apiService.delete(url)
    }

    updatePassword(id, data){
        let url = `${this.#api}/${id}/change-password`
        return apiService.put(url,data)
    }

    search(data={}){
        let url = `${this.#api}/search/all`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    getAppointments(adminUserId, data = {}, index = null) {
        let url = `${this.#api}/${adminUserId}/appointment`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getAppointmentsCount(adminUserId){
        let url = `${this.#api}/${adminUserId}/appointment/get-totals`
        return apiService.get(url)
    }


    updateUserType(id, data) {
        let url = `${this.#api}/${id}/update/user-type`
        return apiService.post(url, data);
    }
    assignRole(id, data) {
        let url = `${this.#api}/${id}/assign-role`
        return apiService.post(url, data);
    }

    removeRole(id, data) {
        let url = `${this.#api}/${id}/remove-role`
        return apiService.post(url, data);
    }

    userRoles(id) {
        let url = `${this.#api}/${id}/roles`
        return apiService.get(url);
    }

    getUpcomingAppointment(adminUSerId){
        let url = `${this.#api}/${adminUSerId}/upcoming-appointment`;
        return apiService.get(url);
    }
}
