<template>
    <v-app>
        <v-card raised>
            <v-card-title>
                Appointments
                <v-spacer></v-spacer>

                <v-btn
                    class="ma-2 btn btn-standard "
                    small
                    @click="showDialog"
                >
                    <v-icon  x-small>
                        fas fa-calendar
                    </v-icon>
                    Set Working Hour
                </v-btn>
            </v-card-title>
            
            <v-card-text>
                <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                    <div class="row">
                        <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                                outlined
                                dense
                                :items="users"
                                item-text="full_name"
                                item-value="id"
                                v-model="search.user_id"
                                @input="search.user_id = $event !== null ? $event : ''" 
                                clearable
                                label="Search by registered user"
                                v-on:keyup.enter="getAppointments()"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field
                                label="Search by candidate name, email or number"
                                outlined
                                dense
                                v-model="search.user_info"
                                @input="search.user_info = $event !== null ? $event : ''" 
                                clearable
                                v-on:keyup.enter="getAppointments()"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="3">
                            <v-select
                                outlined
                                dense
                                @change="handleSearchDateTypeChange"
                                :items="searchDates"
                                item-text="date"
                                item-value="value"
                                v-model="search.search_date_type"
                                @input="search.search_date_type = $event !== null ? $event : ''" 
                                clearable
                                label="Search"
                                v-on:keyup.enter="getAppointments()"
                            ></v-select>
                        </v-col>

                        <v-col v-if="showDate" class="d-flex" cols="12" sm="12" md="3">
                            <v-menu
                                ref="menu"
                                v-model="date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="search.date"
                                        label="Search By Date "
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        @input="search.date = $event !== null ? $event : ''" 
                                        clearable
                                    >
                                    <template v-slot:label>
                                        Search By Date
                                    </template>  
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    v-model="search.date"
                                    :active-picker.sync="activePicker"
                                    min="1950-01-01"
                                ></v-date-picker>
                                </v-menu>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="2">
                            <v-btn 
                                :loading="paginateLoading"
                                class="mt-1 btn btn-primary text-white" 
                                @click="getAppointments"
                            >
                                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                Search
                            </v-btn>
                        </v-col>
                    </div>
                </div>
            </v-card-text>

            <!-- appointments listing -->
            <v-card-subtitle>
                <div class="d-flex justify-content-between">
                    <div>
                    <v-btn
                        class="ma-2"
                        :class="search.type == 'all' ? 'active' : '' "
                        outlined
                        small
                        @click="setAppointmentType('all')"
                    >
                      <v-icon color="black" x-small>
                        fas fa-circle
                      </v-icon>
                      All
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        :class="search.type == 'scheduled' ? 'active' : ''"
                        outlined
                        small
                        @click="setAppointmentType('scheduled')"
                    >
                      <v-icon color="info" x-small>
                        fas fa-circle
                      </v-icon>
                      Scheduled
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        :class="search.type == 'waiting' ? 'active' : ''"
                        outlined
                        small
                        @click="setAppointmentType('waiting')"
                    >
                      <v-icon color="orange" x-small>
                        fas fa-circle
                      </v-icon>
                      Waiting
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        outlined
                        small
                        :class="search.type == 'attended' ? 'active' : ''"
                        @click="setAppointmentType('attended')"
                    >
                      <v-icon color="green" x-small>
                        fas fa-circle
                      </v-icon>
                      Attended
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        outlined
                        small
                        :class="search.type == 'missed' ? 'active' : ''"
                        @click="setAppointmentType('missed')"
                    >
                      <v-icon color="blue-grey" x-small>
                        fas fa-circle
                      </v-icon>
                      Missed
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        outlined
                        small
                        :class="search.type == 'cancelled' ? 'active' : ''"
                        @click="setAppointmentType('cancelled')"
                    >
                      <v-icon color="red" x-small>
                        fas fa-circle
                      </v-icon>
                      Cancelled
                    </v-btn>
                  </div>
                    <div class="title">
                        Total : {{total}} 
                        <v-btn
                            class="ma-2"
                            outlined
                            small
                            color="black"
                            @click="setAppointmentType('all')"
                        >
                            <v-icon color="black" x-small>
                                fas fa-redo
                            </v-icon>
                            &nbsp; Reset Filter
                        </v-btn>
                    </div>
                </div>
            </v-card-subtitle>

          <div class="table-responsive" style="table-layout: fixed">
            <v-skeleton-loader
                type="table-thead"
                v-if="loading">
            </v-skeleton-loader>

            <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25">
            </v-skeleton-loader>
            <table class="table" v-if="!loading">
              <thead>
              <tr class="px-3">
                <th>Date & Time</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                <th>Status</th>
                <th class="text-center">Action</th>
              </tr>
              </thead>
              <tbody>
              <template>
                <tr  v-for="(item, index) in appointments" :key="index">
                  <td class="px-2"> {{item.formatted_date}} {{item.formatted_month}}  {{item.formatted_time}}</td>
                  <td class="px-2"> {{item.user_name}}</td>
                  <td class="px-2">{{item.user_email}}</td>
                  <td class="px-2">{{item.user_phone}}</td>
                  <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">{{item.branch_name}}</td>
                  <td class="px-2">
                      <span class="badge text-lg" v-bind:class="{
                        'badge-warning': item.status == 'waiting', 'badge-danger': item.status == 'cancelled',
                        'badge-info': item.status == 'scheduled', 'badge-success': item.status == 'attended', 'badge-secondary': item.status == 'missed'
                      }">
                        {{ item.status_text }}
                      </span></td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link" @click="quickView(item)">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                              <span class="navi-text">Quick view</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link" @click="editAppointment(item)">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a class="navi-link" @click="changeStatus(item)">
                                <span class="navi-icon">
                                    <i class="fas fa-bars"></i>
                                </span>
                              <span class="navi-text">Change status</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="appointments.length == 0">
                  <td colspan="7" class="text-center">
                    No items added
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <b-pagination
                v-if="appointments.length > 0"
                class="pull-right mt-7"
                @input="getAppointments"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
            ></b-pagination>
          </div>

          <!-- appointments list ends -->

            <!-- working hour models -->
            <v-dialog
                v-model="dialog"
                max-width="800"
                scrollable
                persistent
            >
                <v-card>
                    <v-toolbar dark>
                        <v-card-title class="text-h5">
                            <span>Set Working Hours</span>
                            <hr>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="closeDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Sunday Available 
                                    <v-switch
                                        @change="handleSundayChange"
                                        v-model="user_working_hour.sunday_available"
                                        :label="user_working_hour.sunday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.sunday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.sunday_available">{{ errors.sunday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="sundayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.sunday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.sunday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.sunday_from" >*{{errors.sunday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.sunday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="sundayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.sunday_to" 
                                        :error="$v.user_working_hour.sunday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.sunday_to" >*{{errors.sunday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.sunday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>

                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Monday Available 
                                    <v-switch
                                        @change="handleMondayChange"
                                        v-model="user_working_hour.monday_available"
                                        :label="user_working_hour.monday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.monday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.monday_available">{{ errors.monday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="mondayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.monday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.monday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.monday_from" >*{{errors.monday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.monday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="mondayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.monday_to" 
                                        :error="$v.user_working_hour.monday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.monday_to" >*{{errors.monday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.monday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>
                            
                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Tuesday Available 
                                    <v-switch
                                        @change="handleTuesdayChange"
                                        v-model="user_working_hour.tuesday_available"
                                        :label="user_working_hour.tuesday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.tuesday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.tuesday_available">{{ errors.tuesday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="tuesdayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.tuesday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.tuesday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.tuesday_from" >*{{errors.tuesday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.tuesday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="tuesdayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.tuesday_to" 
                                        :error="$v.user_working_hour.tuesday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.tuesday_to" >*{{errors.tuesday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.tuesday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>

                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Wednesday Available 
                                    <v-switch
                                        @change="handleWednesdayChange"
                                        v-model="user_working_hour.wednesday_available"
                                        :label="user_working_hour.wednesday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.wednesday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.wednesday_available">{{ errors.wednesday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="wednesdayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.wednesday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.wednesday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.wednesday_from" >*{{errors.wednesday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.wednesday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="wednesdayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.wednesday_to" 
                                        :error="$v.user_working_hour.wednesday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.wednesday_to" >*{{errors.wednesday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.wednesday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>

                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Thursday Available 
                                    <v-switch
                                        @change="handleThursdayChange"
                                        v-model="user_working_hour.thursday_available"
                                        :label="user_working_hour.thursday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.thursday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.thursday_available">{{ errors.thursday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="thursdayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.thursday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.thursday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.thursday_from" >*{{errors.thursday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.thursday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="thursdayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.thursday_to" 
                                        :error="$v.user_working_hour.thursday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.thursday_to" >*{{errors.thursday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.thursday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>

                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Friday Available 
                                    <v-switch
                                        @change="handleFridayChange"
                                        v-model="user_working_hour.friday_available"
                                        :label="user_working_hour.friday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.friday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.friday_available">{{ errors.friday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="fridayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.friday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.friday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.friday_from" >*{{errors.friday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.friday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="fridayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.friday_to" 
                                        :error="$v.user_working_hour.friday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.friday_to" >*{{errors.friday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.friday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>

                            <v-row class="mt-2">
                                <v-col cols="12" md="4">
                                    Saturday Available 
                                    <v-switch
                                        @change="handleSaturdayChange"
                                        v-model="user_working_hour.saturday_available"
                                        :label="user_working_hour.saturday_available ? 'Yes': 'No'"
                                    ></v-switch>
                                    <div class="text-danger" v-if="$v.user_working_hour.saturday_available.$error">This Field is required</div>
                                    <div class="text-danger" v-if="errors.saturday_available">{{ errors.saturday_available[0] }}</div>
                                </v-col>
                                <v-col v-if="saturdayAvailableFlag" cols="12" md="4">
                                    From <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"
                                        v-model="user_working_hour.saturday_from" 
                                        :format="time_format" 
                                        :error="$v.user_working_hour.saturday_from.$error"
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.saturday_from" >*{{errors.saturday_from[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.saturday_from.$error">This field is required</div>
                                </v-col>
                                <v-col v-if="saturdayAvailableFlag" cols="12" md="4">
                                    To <span class="text-danger">*</span>
                                    <vue-timepicker
                                        class="w-full ml-2"  
                                        v-model="user_working_hour.saturday_to" 
                                        :error="$v.user_working_hour.saturday_to.$error"
                                        :format="time_format" 
                                        manual-input 
                                    ></vue-timepicker>
                                    <div class="text-danger" v-if="errors.saturday_to" >*{{errors.saturday_to[0]}}</div>
                                    <div class="text-danger" v-if="$v.user_working_hour.saturday_to.$error">Please Select End Time</div>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            text
                            @click="closeDialog">
                        Cancel
                        </v-btn>

                        <v-btn
                            class="btn btn-primary"
                            medium
                            @click="saveWorkingHour()"
                            :loading="loading"
                        >
                          Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>

      <create-and-update-appointment
          ref="create-and-update-appointment"
          :admin_user_id="admin_user_id"
          @refresh="getAppointments"
      ></create-and-update-appointment>

      <update-appointment-status
          ref="update-status"
          @refresh="getAppointments"
      ></update-appointment-status>

      <quick-view
          ref="quick-view"
      ></quick-view>
    </v-app>

</template>

<script>
import VueTimepicker from "vue2-timepicker";
import 'vue2-timepicker/dist/VueTimepicker.css';
import { required, requiredIf } from "vuelidate/lib/validators";
import QuickView from "@/view/pages/view/appointment/QuickView";
import UpdateAppointmentStatus from "@/view/pages/view/appointment/UpdateAppointmentStatus";
import CreateAndUpdateAppointment from "@/view/pages/view/appointment/CreateAndUpdateAppointment";
import AdminUserWorkingHourService from "@/services/admin-user/working-hour/AdminUserWorkingHourService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import UserService from "@/services/user/UserService";

const admin_user = new AdminUserService();
const userWorkingHour = new AdminUserWorkingHourService();
const user = new UserService();

export default {
  name: "FollowUpAppointment",
  props: ['admin_user_id'],
  components: {
    QuickView,
    VueTimepicker,
    UpdateAppointmentStatus,
    CreateAndUpdateAppointment
  },
  data(){
    return {
      sundayAvailableFlag: false,
      mondayAvailableFlag: false,
      tuesdayAvailableFlag: false,
      wednesdayAvailableFlag: false,
      thursdayAvailableFlag: false,
      fridayAvailableFlag: false,
      saturdayAvailableFlag: false,
      dialog: false,
      loading: false,
      paginateLoading: false,
      user_working_hour: {
          sunday_available: 0,
          sunday_from: "",
          sunday_to: "",
          monday_available: 0,
          monday_from: "",
          monday_to: "",
          tuesday_available: 0,
          tuesday_from: "",
          tuesday_to: "",
          wednesday_available: 0,
          wednesday_from: "",
          wednesday_to: "",
          thursday_available: 0,
          thursday_from: "",
          thursday_to: "",
          friday_available: 0,
          friday_from: "",
          friday_to: "",
          saturday_available: 0,
          saturday_from: "",
          saturday_to: "",
          user_id: null,
      },
      errors:[],
      time_format:'HH:mm',
      appointments: [],
      total: null,
      perPage: null,
      totalCounts: [],
      page: null,
      search: {
          type: 'all',
          date: '',
          user_info: '',
          user_id: '',
          search_date_type: 'today'
      },
      showDate: false,
      searchDates: [
          {
              date: 'Yesterday',
              value: 'yesterday'
          },
          {
              date: 'Today',
              value: 'today'
          },
          {
              date: 'Tomorrow',
              value: 'tomorrow'
          },
          {
              date: 'Select Date',
              value: 'custom_date'
          },
      ],
      users: [],
      activePicker: null,
      date: '',
    }
  },

  validations: {
    user_working_hour:{
    sunday_available: {required},
    sunday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.sunday_available;
      })
    },
    sunday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.sunday_available;
      })
    },
    monday_available: {required},
    monday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.monday_available;
      })
    },
    monday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.monday_available;
      })
    },
    tuesday_available: {required},
    tuesday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.tuesday_available;
      })
    },
    tuesday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.tuesday_available;
      })
    },
    wednesday_available: {required},
    wednesday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.wednesday_available;
      })
    },
    wednesday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.wednesday_available;
      })
    },
    thursday_available: {required},
    thursday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.thursday_available;
      })
    },
    thursday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.thursday_available;
      })
    },
    friday_available: {required},
    friday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.friday_available;
      })
    },
    friday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.friday_available;
      })
    },
    saturday_available: {required},
    saturday_from: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.saturday_available;
      })
    },
    saturday_to: {
      required : requiredIf(function (nestedModel){
        return this.user_working_hour.saturday_available;
      })
    },
  }
  },

  mounted(){
    this.getAppointments();
    this.getAllUsers();
  },

  computed: {
      appointmentType(){
          if(this.search.type == 'all'){
              return 'All'
          }
          if(this.search.type == 'attended'){
              return 'Attended'
          }
          if(this.search.type == 'missed'){
              return 'Missed'
          }
          if(this.search.type == 'waiting'){
              return 'Waiting'
          }
          if(this.search.type == 'cancelled'){
              return 'Cancelled'
          }
      },
      color(){
          if(this.search.type == 'all'){
              return 'indigo';
          }
          if(this.search.type == 'attended'){
              return 'green';
          }
          if(this.search.type == 'waiting'){
              return 'pink';
          }
          if(this.search.type == 'missed'){
              return 'red';
          }
          if(this.search.type == 'cancelled'){
              return 'gray';
          }
      },
  },

  methods:{
    quickView(item) {
      this.$refs['quick-view'].openDialog(item);
    },

    getAllUsers(){
        user
        .all()
        .then(response => {
            this.users = response.data.users;
        })
        .catch((err) => {
        });
    },

    getAppointments() {
        this.paginateLoading = true;
        admin_user
        .getAppointments(this.$route.params.admin_user_id, this.search, this.page)
        .then((response) => {
            this.appointments = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.paginateLoading = false;
        })
        .catch((err) => {
            this.paginateLoading = false;
        })
        .finally(() => {
            this.paginateLoading = false;
        })
    },

    setAppointmentType(type) {
        this.search.type = type;
        this.getAppointments();
    },

    handleSearchDateTypeChange(){
        this.showDate = this.search.search_date_type === 'custom_date';
    },

    handleSundayChange(){
        this.sundayAvailableFlag = !!this.user_working_hour.sunday_available;
    },

    handleMondayChange(){
        this.mondayAvailableFlag = !!this.user_working_hour.monday_available;
    },

    handleTuesdayChange(){
        this.tuesdayAvailableFlag = !!this.user_working_hour.tuesday_available;
    },

    handleWednesdayChange(){
        this.wednesdayAvailableFlag = !!this.user_working_hour.wednesday_available;
    },

    handleThursdayChange(){
        this.thursdayAvailableFlag = !!this.user_working_hour.thursday_available;
    },

    handleFridayChange(){
        this.fridayAvailableFlag = !!this.user_working_hour.friday_available;
    },

    handleSaturdayChange(){
        this.saturdayAvailableFlag = !!this.user_working_hour.saturday_available;
    },

    getUserWorkingHour(){
        userWorkingHour
        .index(this.admin_user_id)
        .then((response)=>{
            let working_hour = response.data.data;
            if(working_hour != null){
                this.user_working_hour = {
                    sunday_available: working_hour.sunday_available,
                    sunday_from: working_hour.sunday_from ? working_hour.sunday_from : "",
                    sunday_to: working_hour.sunday_to ? working_hour.sunday_to : "",
                    monday_available: working_hour.monday_available,
                    monday_from: working_hour.monday_from ? working_hour.monday_from : "",
                    monday_to: working_hour.monday_to ? working_hour.monday_to : "",
                    tuesday_available: working_hour.tuesday_available,
                    tuesday_from: working_hour.tuesday_from ? working_hour.tuesday_from : "",
                    tuesday_to: working_hour.tuesday_to ? working_hour.tuesday_to : "",
                    wednesday_available: working_hour.wednesday_available,
                    wednesday_from: working_hour.wednesday_from ? working_hour.wednesday_from : "",
                    wednesday_to: working_hour.wednesday_to ? working_hour.wednesday_to : "",
                    thursday_available: working_hour.thursday_available,
                    thursday_from: working_hour.thursday_from ? working_hour.thursday_from : "",
                    thursday_to: working_hour.thursday_to ? working_hour.thursday_to : "",
                    friday_available: working_hour.friday_available,
                    friday_from: working_hour.friday_from ? working_hour.friday_from : "",
                    friday_to: working_hour.friday_to ? working_hour.friday_to : "",
                    saturday_available: working_hour.saturday_available,
                    saturday_from: working_hour.saturday_from ? working_hour.saturday_from : "",
                    saturday_to: working_hour.saturday_to ? working_hour.saturday_to : "",
                    user_id: working_hour.user_id,
                };

                this.user_working_hour.sunday_available ? this.sundayAvailableFlag = true : '';
                this.user_working_hour.monday_available ? this.mondayAvailableFlag = true : '';
                this.user_working_hour.tuesday_available ? this.tuesdayAvailableFlag = true : '';
                this.user_working_hour.wednesday_available ? this.wednesdayAvailableFlag = true : '';
                this.user_working_hour.thursday_available ? this.thursdayAvailableFlag = true : '';
                this.user_working_hour.friday_available ? this.fridayAvailableFlag = true : '';
                this.user_working_hour.saturday_available ? this.saturdayAvailableFlag = true : '';
            }

        })
    },

    showDialog(){
        this.getUserWorkingHour();
        this.dialog = true;
    },

    resetForm(){
        this.user_working_hour = {
            sunday_available: 0,
            sunday_from: "",
            sunday_to: "",
            monday_available: 0,
            monday_from: "",
            monday_to: "",
            tuesday_available: 0,
            tuesday_from: "",
            tuesday_to: "",
            wednesday_available: 0,
            wednesday_from: "",
            wednesday_to: "",
            thursday_available: 0,
            thursday_from: "",
            thursday_to: "",
            friday_available: 0,
            friday_from: "",
            friday_to: "",
            saturday_available: 0,
            saturday_from: "",
            saturday_to: "",
            user_id: null,
        };
        this.$v.$reset();
        this.errors = [];

    },

    closeDialog(){
        this.resetForm();
        this.dialog = false;
    },

    saveWorkingHour(){
        this.$v.$touch()
        if (this.$v.$error) {
            setTimeout(() => {
            this.$v.$reset()
            }, 3000);
        } else {
            this.loading = true;
            userWorkingHour
            .create(this.user_working_hour, this.admin_user_id)
            .then((response) => {
                this.$emit('work-hour-saved');
                this.getUserWorkingHour();
                this.$snotify.success('Work hour saved successfully');
                this.closeDialog();
            })
            .catch((err) => {
                this.loading = false;
                this.errors = err.errors;
                this.$snotify.error('Oops looks like something went wrong');
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },

    editAppointment(item) {
      this.$refs['create-and-update-appointment'].editAppointment(item);
    },

    changeStatus(appointment){
      this.$refs['update-status'].updateStatus(appointment);
    },

  },
}
</script>
